import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SignupLayout from '../sign-up-layout';
import FormInput from '../../components/form-input';
import Button from '../../components/button';
import { setField, sendOtp, resendOtp } from '../../actions/login-actions';
import { formatTime } from '../../utils/string-utils';
import Checkbox from '../../components/checkbox';
import qs from 'qs';

const OAuthOTPPage = ({ setField, login, sendOtp, resendOtp }) => {

  const { resendTimer, isLoading, isTrustedDevice } = login;
  const [ minutes, setMinutes ] = useState(-1);
  const [ seconds, setSeconds ] =  useState(-1);
  const [ deviceDays, setDeviceDays ] = useState(null);
  const { method, resend, msg } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const methodTexts = {
    'email/sms': 'We sent you a security code to your phone and email',
    'email': 'We sent you a security code to your email',
    'sms': 'We sent you a security code to your phone',
    'totp': 'Please type in the code from your authentication app'
  };

  useEffect(() => {
    const deviceDays = localStorage.getItem('deviceDays');
    if(deviceDays) {
      setDeviceDays(deviceDays);
    }
    setField('resendTimer', resend);
  }, []);

  useEffect(() => {
    if(!!resendTimer) {
      const startTime = new Date().getTime();
      let timer = setInterval(() => {
        const currentTime = new Date().getTime();
        const _minutes = minutes < 0 ? parseInt(resendTimer / 60, 10) : minutes;
        const _seconds = seconds < 0 ? resendTimer % 60 : seconds;
        const diff = Math.ceil((currentTime - startTime)/1000);
        const secondsLeft = Math.floor((_minutes * 60 + _seconds - diff) % 60);
        const minutesLeft = Math.floor((_minutes * 60 + _seconds - diff) / 60);

        if (startTime + resendTimer * 1000 - currentTime <= 0) {
          setField('resendTimer', false);
          clearInterval(timer);
        }

        setMinutes(minutesLeft);
        setSeconds(secondsLeft);
      }, 100);

      return () => {
        setField('resendTimer', false);
        clearInterval(timer);
      };
    }
  }, [resendTimer]);

  const handleTrustedDevice = () => {
    setField('isTrustedDevice', !isTrustedDevice);
  };

  const securityCodeError = msg && msg.replaceAll('+');

  return (
    <SignupLayout
      headerClassName="-y-lg"
    >
      <div className="page_header -space-sm">
        <h1 className="js-page-title page_title">Enter security code</h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          <form className="form" onSubmit={(e) => {
            e.preventDefault();
            sendOtp();
          }}>
            <div className="layer -space-down-xxl font-size-secondary-responsive">
              <p className="color-light-gray">
                {methodTexts[method]}
              </p>
            </div>

            <div className="layer -space-down-md">
              <div className="form-input-container">
                <FormInput
                  transparency="half"
                  className="full-width"
                  label="Security code"
                  type="tel"
                  inputName="security-code"
                  value={login.securityCode || ''}
                  onChange={(value) => {
                    setField('securityCodeError', '');
                    setField('securityCode', value);
                  }}
                  errorMessage={securityCodeError}
                  invalid={!!securityCodeError}
                  mask="99999999"
                  hideMask={true}
                  autoFocus
                />
              </div>
            </div>

            <div className="layer -space-down-xxl font-size-secondary-responsive">
              {method !== 'totp' ? (
                <p className="color-light-gray">
                  <Button
                    transparency="full"
                    ySize="min"
                    className="js-cancel-button button-clean page_control -no-border -cancel font-weight-bold"
                    onClick={resendOtp}
                    disabled={!!resendTimer}
                  >
                    {!resendTimer || minutes < 0 || seconds < 0 ? 
                      'Resend the code' : 
                      `You can resend the code in ${formatTime(minutes)}:${formatTime(seconds)}`}
                  </Button>
                </p>
              ) : null}
              
              <p className="color-light-gray">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={process.env.REACT_APP_HELP_DESK_URL}
                >
                  Can’t access the code?
                </a>
              </p>
            </div>
            {deviceDays ? (
              <div className="layer -space-up-md -space-down-xxl">
                <Checkbox
                  inputName="terms-and-condition"
                  label={
                    <span>
                      Don't ask for verification on this device for {deviceDays} days
                    </span>
                  }
                  className="-bordered"
                  checked={isTrustedDevice}
                  onChange={handleTrustedDevice}
                />
              </div>
            ) : null}
          </form>
        </div>
      </div>
      <div className="page_controls">
        <Button
          transparency="full"
          xSize="full"
          className="js-cancel-button page_control -no-border -cancel font-weight-bold"
          to={`/auth/login${localStorage.getItem('oauthParams')}`}
        >
          Cancel
        </Button>
        <Button
          color="blue"
          xSize="full"
          disabled={!login.securityCode || login.securityCode.replace('_', '').length < 6 || isLoading}
          className="js-submit-button page_control -submit"
          loading={login.isLoading}
          onClick={() => sendOtp()}
        >
          Continue
        </Button>
      </div>
    </SignupLayout>
  );
};

OAuthOTPPage.propTypes = {
  setField: PropTypes.func,
  sendOtp: PropTypes.func,
  resendOtp: PropTypes.func,
  login: PropTypes.shape({
    securityCode: PropTypes.string,
    isLoading: PropTypes.bool,
    securityCodeError: PropTypes.string,
    resendTimer: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    isTrustedDevice: PropTypes.bool,
  })
};

const mapStateToProps = (state) => ({
  login: state.login
});

export default connect(mapStateToProps, {
  setField,
  sendOtp,
  resendOtp,
})(OAuthOTPPage);
