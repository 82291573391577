import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as types from '../constants/prop-types';
import BasicLayout from './basic-layout';
import Svg from '../components/svg';
import Button from '../components/button';
import CreditCardDetails from '../components/credit-cards/credit-cards-details';
import cn from 'classnames';

import { getStatusColor, getIconName } from '../constants/card-account-constants';
import { setField } from '../actions/card-account-actions';

const CreditCardsPage = ({ isLoading, cardAccounts, setField }) => {
  const [ isDetailsModalOpen, setIsDetailsModalOpen ] = useState(false);

  const handleOpenDetailsModal = (card) => () => { 
    setIsDetailsModalOpen(true);
    setField('cardDetails', card);
  };

  const handleCloseDetailsModal = () => { 
    setIsDetailsModalOpen(false);
    setTimeout(() => {
      setField('cardDetails', {});
    }, 500);
  };

  return (
    <BasicLayout innerWrapperClassName="cards-page__wrapper">
      <main className="cards-page">
        <header>
          <h1>Credit Cards</h1>
          <p>Credit cards could be used for Order payments, transfers and payments backup</p>
        </header>
        <div className="cards-page__content">
          {Array.isArray(cardAccounts) && cardAccounts.length > 0 ? (
            <div className="button-group full-width">
              <div className="button-group_body">
                {cardAccounts.filter((card) => card.status !== 'deleted').map(card => (
                  <Button
                    key={card.card_account_id}
                    xSize="full"
                    ySize="xl"
                    className="button-group_item"
                    color="white"
                    onClick={handleOpenDetailsModal(card)}
                    disabled={isLoading}
                  >
                    <div className="button_head">
                      <div className="button_content-primary js-nickname">{card.user_description || 'Credit Card'}</div>
                      <div className="button_content-secondary">
                        <Svg name={getIconName(card.bank_name)} className="icon cards-page__icon" viewBox="0 0 34 24" />
                        <span className="account-number-bullets">&bull;&bull;</span>{card.account_number}&nbsp;
                        {card.is_default_backup && (
                          <div className="label">Default backup</div>
                        )}
                      </div>
                    </div>
                    <div className="button_tail">
                      <div className="button_content-primary font-weight-regular">
                        <div className={cn('button_content-secondary js-verified-status', getStatusColor(card.status))}>
                          {`${card.status.slice(0,1).toUpperCase()}${card.status.slice(1)}`}
                        </div>
                      </div>
                    </div>
                  </Button>
                ))}
              </div>
            </div>
          ) : (
            <Fragment>
              <Svg width={120} height={120} name="credit-card-add" className="cards-icon" />
              <p>No credit cards added yet</p>
            </Fragment>
          )}
        </div>
      </main>
      <div className="page_controls -align-top-desktop">
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button page_control -submit"
          to="/credit-cards/add"
          disabled={isLoading}
        >
            Add Credit Card
        </Button>
      </div>
      <CreditCardDetails
        show={isDetailsModalOpen}
        onClose={handleCloseDetailsModal}
        closeButton
        containerClassName="cards-page__details"
      />
    </BasicLayout>
  );
};

CreditCardsPage.propTypes = {
  isLoading: PropTypes.bool,
  cardAccounts: PropTypes.arrayOf(types.CreditCardShape),
  setField: PropTypes.func,
};

const mapStateToProps = (state, props) => ({
  cardAccounts: state.wallet.cardAccounts,
});

export default connect(mapStateToProps, { setField })(CreditCardsPage);