import { delay, eventChannel } from 'redux-saga';
import { takeLatest, put, call, fork, take, select } from 'redux-saga/effects';
import { path, propOr } from 'ramda';
import { qsParse } from '../utils/string-utils';
import jwtDecode from 'jwt-decode';
import qs from 'qs';
import VALIDATION from '../utils/validation';

import CONST, { PHONE_PROMPT_STATUSES } from '../constants/login-constants';
import { setField, setError, cleanErrors } from '../actions/login-actions';
import { addNotification } from '../actions/toast-actions';
import { setField as setUserField } from '../actions/user-actions';
import REQUESTS from '../utils/requests';
import { setAuthToken, setRefreshToken, setScopes, helpDeskUrl, CLIENT_ID, CLIENT_SECRET } from '../utils/api';
import { formatMaskedEmail, formatErrorMessage } from '../utils/string-utils';
import { redirectToOAuthLogin } from '../utils/redirects';

const STATE_TYPES = {
  USER: 'user',
  FAIL: 'fail',
  EMPTY: '',
  TF_AUTH: 'tfa_auth',
  TF_LINK: 'tfa_link',
  TF_PENDING: 'tfa_pending',
  TF_OTP: 'tfa_otp'
};

const CALL_DELAY = 3000;

function* pageEmitter(status, page) {
  yield put(setField('isLoading', false));

  if (status >= 400) {
    yield put(setField('step', page));
  }
}

function* toastEmitter(status, errors = []) {
  yield put(setField('isLoading', false));

  if (status >= 500) {
    yield put(addNotification(`Something went wrong. Please try again or 
      contact ${helpDeskUrl} if the error persists.`, 500));
  };

  if ([401, 403, 408].indexOf(status) === -1 && status >= 400 && status < 500) {
    for(let i = 0; i < errors.length; i++) {
      yield put(addNotification(formatErrorMessage(errors[i])));
    }
  };
}

function* checkForAuthLink(numCalls, redirectPath) {
  try {
    const { step } = yield select(state => state.login);

    if (step === CONST.STEP_SENT_LINK || step === CONST.STEP_PAYPHONE_AUTODETECT) {
      yield call(delay, CALL_DELAY);

      const { data, success, status } = yield REQUESTS.CHECK_SOFT_LINK();

      if (success) {
        const { state } = data[0];

        if (state === STATE_TYPES.FAIL) {
          yield put(setField('step', CONST.STEP_UNABLE_TO_LOGIN));
          yield put(setField('payfoneIsLoading', false));

          return;
        }

        if(state === STATE_TYPES.EMPTY) {
          yield put(setField('step', CONST.STEP_ERROR_TRY_AGAIN));
          yield put(setField('payfoneIsLoading', false));

          return;
        }

        if (state === STATE_TYPES.USER) {
          yield call(checkForConsent, data[0].required_consents, redirectPath);
          yield put(setField('payfoneIsLoading', false));
        }
      } else {
        yield call(pageEmitter, status, CONST.STEP_UNABLE_TO_LOGIN);
        yield put(setField('payfoneIsLoading', false));
      }

      yield call(checkForAuthLink, numCalls + 1, redirectPath);
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* pendingLinkFlow(redirect) {
  try {
    const redirectPath = redirect || '/dashboard';
    const { data, success, status, errors } = yield REQUESTS.CHECK_SOFT_LINK();

    if (success) {
      if (data[0].state === STATE_TYPES.FAIL || data[0].state === STATE_TYPES.EMPTY) {
        yield put(setField('step', CONST.STEP_UNABLE_TO_LOGIN));
      } else {
        yield put(setField('lastPhoneDigits', data[0].tfa_phone));
        yield put(setField('maskedEmail', formatMaskedEmail(data[0].tfa_email)));
        yield put(setField('expiration', data[0].tfa_expires));

        yield put(setField('step', CONST.STEP_LINK_SENT));
        yield call(checkForAuthLink, 0, redirectPath);
      }
    } else {
      yield call(toastEmitter, status, errors);
    }
  } catch (e) {
    yield put(addNotification('Something went wrong'));
    yield put(setField('isLoading', false));
  }
}


function* authLinkFlow(redirect, phoneNumber) {
  try {
    const { success, data, status, errors } = yield REQUESTS.SOFT_LINK_AUTH(phoneNumber);
    const redirectPath = redirect || '/dashboard';

    if (success) {
      if (data[0].state && data[0].state === STATE_TYPES.USER) {
        yield call(checkForConsent, data[0].required_consents, redirectPath);

        return;
      }

      if (data[0].state === STATE_TYPES.FAIL || data[0].state === STATE_TYPES.EMPTY) {
        yield put(setField('step', CONST.STEP_UNABLE_TO_LOGIN));

        return;
      }

      if(data[0].state === STATE_TYPES.TF_OTP) {
        yield put(setField('step', CONST.STEP_OTP));

        return;
      }

      yield put(setField('lastPhoneDigits', data[0].tfa_phone));
      yield put(setField('maskedEmail', formatMaskedEmail(data[0].tfa_email)));
      yield put(setField('expiration', data[0].tfa_expires));

      yield put(setField('step', CONST.STEP_SENT_LINK));
      yield put(setField('isLoading', false));

      yield call(checkForAuthLink, 0, redirectPath);
    } else {
      yield call(toastEmitter, status, errors);
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* createAndDeleteIframeListener(channel) {
  while (true) {
    const redirectPath = yield take(channel);
    const { phonePrompt } = yield select(state => state.login);

    try {
      const {
        data,
        success,
        status: linkStatus,
        errors: linkErrors,
      } = yield REQUESTS.CHECK_SOFT_LINK();

      if (success) {
        if (data[0].state === STATE_TYPES.USER) {
          yield put(setField('isLoading', false));
          yield call(checkForConsent, data[0].required_consents, redirectPath);
          yield put(setField('payfoneIsLoading', false));
          return;
        }

        if (data[0].state === STATE_TYPES.FAIL) {
          yield put(setField('step', CONST.STEP_UNABLE_TO_LOGIN));
          yield put(setField('payfoneIsLoading', false));
          return;          
        }

        if (data[0].state === STATE_TYPES.TF_LINK || data[0].state === STATE_TYPES.TF_AUTH || data[0].state === STATE_TYPES.EMPTY) {
          yield put(setField('isLoading', false));

          if (phonePrompt === PHONE_PROMPT_STATUSES.NO) {
            yield call(authLinkFlow, redirectPath);
          } else {
            yield put(setField('payfoneIsLoading', false));
            yield put(setField('step', CONST.STEP_PHONE_NUMBER));
          }
        }
      } else {
        yield call(toastEmitter, linkStatus, linkErrors);
        yield put(setField('payfoneIsLoading', false));
      }
    } catch (e) {
      yield put(setField('payfoneIsLoading', false));
      yield put(setField('isLoading', false));
    }
  }
}

function* checkForConsent(consents, redirectPath) {
  if (consents && consents.indexOf('user-agreement') !== -1) {
    yield put(setField('step', CONST.STEP_CONSENT));
  } else {
    window.location.assign(redirectPath);
  }
}

function* consentFlow() {
  try {
    const { userId, agreement } = yield select(state => state.login);
    const query = qsParse(window.location.search, { ignoreQueryPrefix: true });
    
    const redirectPath = propOr('/dashboard', 'from', query);

    if (agreement) {
      const { success, status, errors } = yield REQUESTS.CONSENT_AGREEMENT(userId, 'user-agreement');

      if (success) {
        window.location.assign(redirectPath);
      } else {
        if ([401, 403, 408].indexOf(status) === -1 && status >= 400 && status < 500)  {
          yield put(setField('step', CONST.STEP_UNABLE_TO_LOGIN));
        }

        if (status >= 500) {
          yield call(toastEmitter, status, errors);
        }
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

function* verifyPhoneNumberFlow() {
  try {
    const { phoneNumber } = yield select(state => path(['login'], state));
    const query = qsParse(window.location.search, { ignoreQueryPrefix: true });

    if (phoneNumber !== null) {
      const phoneValidation = VALIDATION.validatePhoneNumber(phoneNumber);

      if (phoneValidation.success) {
        yield call(authLinkFlow, propOr('/dashboard', 'from', query), phoneNumber);
      } else {
        yield put(setError('phoneNumber', phoneValidation.message));
      }
    } else {
      yield call(authLinkFlow, propOr('/dashboard', 'from', query));
    }
  } catch (error) {
    yield put(setField('isLoading', false));
    console.log(error);
  }
};

function* generateNewLink() {
  const oauthParams = yield select(state => path(['login', 'oauthParams'], state));
  const { avi } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
 
  return REQUESTS.OAUTH_AUTHORIZE({ ...oauthParams, avi });
}

function* emailPasswordFlow() {
  try {
    const email = yield select(state => path(['login', 'email'], state));
    const password = yield select(state => path(['login', 'password'], state));
    const isOAuth = yield select(state => path(['login', 'isOAuth'], state));
    const oauthParams = yield select(state => path(['login', 'oauthParams'], state));
    const OBTAIN_TOKEN_REQUEST = isOAuth ? REQUESTS.OBTAIN_TOKEN_OAUTH : REQUESTS.OBTAIN_TOKEN;
    const { success, data, errors, status } = yield OBTAIN_TOKEN_REQUEST({ username: email, password, ...oauthParams });

    if(isOAuth && success) {
      yield REQUESTS.OAUTH_AUTHORIZE({ ...oauthParams, avi: data[0].avi });
    }

    yield put(cleanErrors());

    if (success && !isOAuth) {
      const token = data[0].access_token;
      const type = data[0].state;
      const phonePrompt = data[0].tfa_phone_prompt;

      setAuthToken(token);

      const query = qsParse(window.location.search, { ignoreQueryPrefix: true });
      const redirectPath = propOr('/dashboard', 'from', query);
      const userId = jwtDecode(token).sub;

      yield put(setField('userId', userId));
      yield put(setField('phonePrompt', phonePrompt));

      switch (type) {
        case STATE_TYPES.USER:
          yield call(checkForConsent, data[0].required_consents, redirectPath);
          break;
        case STATE_TYPES.TF_LINK: 
          if (phonePrompt === PHONE_PROMPT_STATUSES.NO) {
            yield call(authLinkFlow, redirectPath);
          } else {
            yield put(setField('step', CONST.STEP_PHONE_NUMBER));
          }
          break;
        case STATE_TYPES.TF_AUTH: {
          yield put(setField('mnoLink', data[0].tfa_auth));
          yield put(setField('step', CONST.STEP_PAYPHONE_AUTODETECT));
          break;
        }

        case STATE_TYPES.TF_PENDING:
          yield call(pendingLinkFlow, redirectPath);
          break;

        case STATE_TYPES.FAIL:
        case STATE_TYPES.EMPTY:
        default: yield put(setField('step', CONST.STEP_UNABLE_TO_LOGIN));
      }
    } else {
      if (status === 401) {
        yield put(setError('general', 'Incorrect email or password'));
      } else {
        yield call(toastEmitter, status, errors);
        if(Array.isArray(errors) && errors.filter((err) => err.detail === CONST.ERROR_EXPIRED_ATTEMPT).length > 0) {
          redirectToOAuthLogin();
        }
      }
    }
  } catch (error) {
    yield put(setField('isLoading', false));
    throw error;
  }
}

function* loginStepFlow(action) {
  try {
    yield put(setField('isLoading', true));

    switch (action.payload.step) {
      case CONST.STEP_EMAIL_PASSWORD: yield call(emailPasswordFlow); break;
      case CONST.STEP_PHONE_NUMBER: yield call(verifyPhoneNumberFlow); break;
      case CONST.STEP_CONSENT: yield call(consentFlow); break;
      case CONST.STEP_OTP: yield call(otpFlow); break;

        // eslint-disable-next-line no-console
      default: console.log(action.payload.step);
    }

    yield put(setField('isLoading', false));
  } catch (error) {
    throw error;
  }
}

function closePopup (timeout, timer, emit) {
  clearInterval(timer);
  clearTimeout(timeout);
  const query = qsParse(window.location.search, { ignoreQueryPrefix: true });
  emit(propOr('/dashboard', 'from', query));
}

function popupEmitter(mnoLink) {
  return eventChannel((emit) => {
    const url = `http://${window.location.host}/payfone?url=${btoa(mnoLink)}`;
    const payfoneWindow = window.open(url, 'Payfone','height=667,width=375');
    let timeout = null;
    let timer = null;

    timeout = setTimeout(function() {
      closePopup(timeout, timer, emit);
    }, process.env.REACT_APP_LOGIN_TIMEOUT);

    timer = setInterval(function() {
      if(payfoneWindow.closed) {
        closePopup(timeout, timer, emit);
      }
    }, 500);

    return () => {};
  });
}

function* openPayfonePopupFlow() {
  yield put(setField('payfoneIsLoading', true));

  const { mnoLink } = yield select(state => state.login);
  const emitter = popupEmitter(mnoLink);

  yield fork(createAndDeleteIframeListener, emitter);
}

function* setFieldInterceptor(action) {
  if (action.payload.field === 'step') {
    yield put(setField('payfoneIsLoading', false));
  }
}

function* oauthCheckForAuthLink(numCalls) {
  try {
    if(window.location.pathname && window.location.pathname.includes('/auth/pending')) {
      yield call(delay, CALL_DELAY);

      const { linkExpired } = yield select(state => state.login);
      const { avi } = qs.parse(localStorage.getItem('oauthParams'), { ignoreQueryPrefix: true });
      const { data, success } = yield REQUESTS.CHECK_SOFT_LINK(null, avi);
      const { isTrustedDevice } = yield select(state => state.login);
      const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if(isTrustedDevice) {
        params.device = 'trusted';
      }
  
      if (success) {
        const { state } = data[0];

        if (state === STATE_TYPES.FAIL) {
          yield put(setField('payfoneIsLoading', false));
          yield REQUESTS.OAUTH_AUTHORIZE(params);
          return;
        }

        // if(state === STATE_TYPES.EMPTY) {
        //   yield put(setField('payfoneIsLoading', false));
        //   yield REQUESTS.OAUTH_AUTHORIZE(qs.parse(window.location.search, { ignoreQueryPrefix: true }));
        //   return;
        // }

        if (state === STATE_TYPES.USER) {
          yield REQUESTS.OAUTH_AUTHORIZE(params);
          yield put(setField('payfoneIsLoading', false));
        }
      } else {
        yield put(setField('payfoneIsLoading', false));
      }

      if(!linkExpired) {
        yield call(oauthCheckForAuthLink, numCalls + 1);
      }
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* oauthPendingLinkFlow(redirect) {
  try {
    const redirectPath = redirect || '/dashboard';
    const { avi } = qs.parse(localStorage.getItem('oauthParams'), { ignoreQueryPrefix: true });
    const { isTrustedDevice } = yield select(state => state.login);
    const { data, success, status, errors } = yield REQUESTS.CHECK_SOFT_LINK(null, avi);
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if(isTrustedDevice) {
      params.device = 'trusted';
    }

    if (success) {
      if (!!data[0].state && (data[0].state === STATE_TYPES.USER || data[0].state === STATE_TYPES.FAIL)) {
        yield REQUESTS.OAUTH_AUTHORIZE(params);
      } else {
        yield call(oauthCheckForAuthLink, 0, redirectPath);
      }
    } else {
      yield call(toastEmitter, status, errors);
    }
  } catch (e) {
    yield put(addNotification('Something went wrong'));
    yield put(setField('isLoading', false));
  }
}

function* oauthOtpFlow() {
  try {
    yield put(setField('isLoading', true));
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const { securityCode, isTrustedDevice } = yield select(state => state.login);
    params.otp = securityCode;
    if(isTrustedDevice) {
      params.device = 'trusted';
    }
    const { url, redirected } = yield REQUESTS.OAUTH_AUTHORIZE(params);

    if(url && redirected) {
      const redirectUrl = new URL(url);
      const { msg } = qs.parse(redirectUrl.search, { ignoreQueryPrefix: true });
      yield put(setField('securityCodeError', msg));
    }
    yield put(setField('isLoading', false));
  } catch(e) {
    console.warn(e);
    yield put(addNotification('Something went wrong'));
    yield put(setField('isLoading', false));
  }
}

function* resendOtp() {
  try {
    const { avi, resend } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    yield put(setField('resendTimer', parseInt(resend, 10) || CONST.OTP_RESEND_TIMER));
    yield REQUESTS.RESEND_OTP(avi);
  } catch(e) {
    yield put(addNotification('Something went wrong'));
    yield put(setField('isLoading', false));
  }
}

function* otpFlow() {
  try {
    const { securityCode } = yield select(state => state.login);
    const { success, data } = yield REQUESTS.FINISH_TFA_OTP(securityCode);

    if (success) {
      const { state } = data[0];

      yield put(setField('isLoading', false));


      if (state === STATE_TYPES.FAIL) {
        yield put(setField('step', CONST.STEP_UNABLE_TO_LOGIN));
        
        return;
      }

      if(state === STATE_TYPES.EMPTY) {
        yield put(setField('step', CONST.STEP_ERROR_TRY_AGAIN));

        return;
      }

      if (state === STATE_TYPES.USER) {
        yield call(checkForConsent, data[0].required_consents, '/dashboard');
      }
    } else {
      yield put(setField('securityCodeError', 'Invalid security code'));
      yield put(setField('isLoading', false));
    }

  } catch(e) {
    console.warn(e);
    yield put(addNotification('Something went wrong'));
    yield put(setField('isLoading', false));
  }
}

function* getOAuthTokenFlow() {
  try {
    const { code, redir = '' } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    let _redir = redir.includes('/auth/') || redir.includes('setup') || redir.includes('login')  ? '/dashboard' : redir.replaceAll('§', '&');

    const params = {
      grant_type: 'authorization_code',
      code,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      redirect_uri: `${window.location.origin}/authcode?redir=${redir}`,
      code_verifier: localStorage.getItem('code_verifier')
    };

    const result = yield REQUESTS.GET_OAUTH_TOKEN(params);

    localStorage.clear();
    setAuthToken(result.access_token);
    setRefreshToken(result.refresh_token);
    setScopes(result.scope);
    setUserField(['user', 'user_id'], result.user_id);

    window.location.assign(`${_redir}`);
  } catch(e) {
    yield put(addNotification('Something went wrong'));
    yield put(setField('isLoading', false));
  }
}


function* refreshOAuthTokenFlow() {
  const scopes = localStorage.getItem('scopes');
  const refreshToken = localStorage.getItem('refreshToken');

  try {
    const params = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      scopes
    };

    const result = yield REQUESTS.GET_OAUTH_TOKEN(params);

    if(result.access_token) {
      setAuthToken(result.access_token);
      setRefreshToken(result.refresh_token);
    } else {
      redirectToOAuthLogin();
    }

    
  } catch(e) {
    yield put(addNotification('Something went wrong'));
    yield put(setField('isLoading', false));
  }
}

function* getConsent({ consent }) {
  try {
    yield put(setField('isLoading', true));
    const { data } = yield REQUESTS.GET_CONSENT(consent);

    yield put(setField('consent', data));
  } catch(e) {
    yield put(addNotification('Something went wrong'));
    yield put(setField('isLoading', false));
  }
}

function* oauthSendConsent() {
  try {
    yield put(setField('isLoading', true));
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    yield REQUESTS.OAUTH_AUTHORIZE({ ...params, 'consent:user-agreement': 1 });
    yield put(setField('isLoading', false));
  } catch(e) {
    yield put(addNotification('Something went wrong'));
    yield put(setField('isLoading', false));
  }
}

function* getAviStatus({ avi }) {
  try {
    yield put(setField('isLoading', true));
    const { data } = yield REQUESTS.GET_AVI_STATUS(avi);
    if(data[0].id) {
      if(data[0].redir) {
        window.location.assign(`${data[0].redir}`);
      } else {
        window.location.assign(`${window.location.origin}/auth/error`);
      }
    }
    yield put(setField('isLoading', false));
  } catch(e) {
    yield put(addNotification('Something went wrong'));
    yield put(setField('isLoading', false));
  }
} 

function* loginSaga() {
  yield takeLatest(CONST.END_STEP, loginStepFlow);
  yield takeLatest(CONST.SET_FIELD, setFieldInterceptor);
  yield takeLatest(CONST.OPEN_POPUP, openPayfonePopupFlow);
  yield takeLatest(CONST.OAUTH_PENDING, oauthPendingLinkFlow);
  yield takeLatest(CONST.SEND_OTP, oauthOtpFlow);
  yield takeLatest(CONST.RESEND_OTP, resendOtp);
  yield takeLatest(CONST.GET_OAUTH_TOKEN, getOAuthTokenFlow);
  yield takeLatest(CONST.REFRESH_OAUTH_TOKEN, refreshOAuthTokenFlow);
  yield takeLatest(CONST.GET_CONSENT, getConsent);
  yield takeLatest(CONST.OAUTH_SEND_CONSENT, oauthSendConsent);
  yield takeLatest(CONST.GET_AVI_STATUS, getAviStatus);
  yield takeLatest(CONST.GENERATE_NEW_LINK, generateNewLink);
}

export default [loginSaga];
